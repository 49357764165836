import { showNotification } from 'components/common/showNotification';
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { clearFieldsCache } from 'redux/actions/fields';
import { history, URL_CONSTANTS } from 'utils/history';
import { useLimitCreation } from '../limit-creation';
import { ArchiveError } from './archive/archive-error/archive-error.component';
import { ConfirmArchive } from './archive/confirm-archive/confirm-archive.component';
import {
  ArchivingInternalProvider,
  ArchivingModalsEnum,
  initialInternalState
} from './archiving-internal.provider';
import { FieldModel } from './archiving.model';
import * as service from './archiving.service';
import { getFieldWithParentName, mapOverlappingFields } from './archiving.util';
import { ConfirmUnarchive } from './unarchive/confirm-unarchive/confirm-unarchive.component';
import { UnarchiveError } from './unarchive/unarchive-error/unarchive-error.component';
import { ForceArchive } from './archive/force-archive/force-archive.component';
import { isFieldCreatedOrEditedToday } from '../fields.util';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ArchivingContextValue {
  fields: FieldModel[];
  loading: boolean;
  loadingRegionNames: boolean;
  openConfirmArchive: (fields: { id: string; name: string; valid_since: string | null }[]) => void;
  openConfirmUnarchive: (field: { id: string; name: string; valid_since: string | null }) => void;
  fetchArchivedFields: (force?: boolean) => Promise<FieldModel[]>;
}
const ArchivingContext = createContext<ArchivingContextValue>({} as ArchivingContextValue);

export function useArchiving() {
  return useContext(ArchivingContext);
}

const getOrgAndFarmId = () => {
  const pathname = window.location.pathname;
  const farmId = pathname.split('/')[5];
  const orgId = pathname.split('/')[3];

  return [orgId, farmId];
};

export function ArchivingProvider(props: { children?: ReactNode }) {
  const { farmSettingsArchivingCws13407EnableForceArchive } = useFlags();
  const { t } = useTranslation();

  const [state, setState] = useState(initialInternalState);
  const [fields, setFields] = useState<FieldModel[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingRegionNames, setLoadingRegionNames] = useState(true);
  const [currentFarmId, setCurrentFarmId] = useState<string | null>(null);
  const { showLimitQuotaModal } = useLimitCreation();

  const selectedFieldsIds = state.selectedFields.map((field) => field.id);

  const resetState = () => {
    setState(initialInternalState);
  };

  const triggerModal = (modal: ArchivingModalsEnum) => {
    setState((state) => ({ ...state, modals: { ...state.modals, [modal]: !state.modals[modal] } }));
  };

  const triggerLoading = () => {
    setState((state) => ({ ...state, loading: !state.loading }));
  };

  const goToSeasons = () => {
    const [orgId, farmId] = getOrgAndFarmId();
    resetState();
    history.push(URL_CONSTANTS.LANDING_PROPERTY_TABLE_VIEW({ orgId, farmId }));
  };

  const archiveFields = (force: boolean = false) => {
    const invalidFields = state.selectedFields.filter(({ valid_since }) =>
      isFieldCreatedOrEditedToday(valid_since)
    );
    if (farmSettingsArchivingCws13407EnableForceArchive && invalidFields.length > 0 && !force) {
      triggerModal(ArchivingModalsEnum.FORCE_ARCHIVE);
    } else {
      const [orgId, farmId] = getOrgAndFarmId();

      triggerLoading();

      const fieldsInActiveSeasonIds = state.fieldsInActiveSeason.map((field) => field.id);
      const fieldsToArchive = selectedFieldsIds.filter(
        (id) => !fieldsInActiveSeasonIds.includes(id)
      );

      service
        .archiveFields(fieldsToArchive, force)
        .then((result) => {
          if (result.success) {
            // TODO: Improve this call when we develop the Cache Service
            clearFieldsCache();

            showNotification(
              'success',
              t('archiving.notifications.field_archived', { count: fieldsToArchive.length }),
              t('archiving.notifications.field_archived_description', {
                count: fieldsToArchive.length
              })
            );
            resetState();
            history.push(URL_CONSTANTS.ARCHIVED_FIELDS({ orgId, farmId }));
          } else if (result.activeSeasonError) {
            setState((state) => ({
              ...state,
              fieldsInActiveSeason: result.activeSeasonError.errors.map((err) => ({
                id: err.field_id,
                name: err.field_name,
                season: err.season.season_name
              }))
            }));
            triggerModal(ArchivingModalsEnum.ARCHIVE_ERROR);
          }
        })
        .finally(() => {
          triggerLoading();
        });
    }
  };

  const unarchiveField = () => {
    if (selectedFieldsIds.length === 1) {
      triggerLoading();

      service
        .unarchiveFields(selectedFieldsIds[0])
        .then((result) => {
          if (result.success) {
            // TODO: Improve this call when we develop the Cache Service
            clearFieldsCache();

            showNotification(
              'success',
              t('archiving.notifications.field_unarchived'),
              t('archiving.notifications.field_unarchived_description')
            );
            resetState();
            fetchArchivedFields(true);
          } else if (result.overlapError) {
            const currentField = result.overlapError.errors[0].field;
            const overlappingFields = result.overlapError.errors[0].fields_overlapped;

            const mappedFields = mapOverlappingFields(currentField, overlappingFields);

            setState((state) => ({
              ...state,
              overlappingFields: mappedFields
            }));
            triggerModal(ArchivingModalsEnum.CONFIRM_UNARCHIVE);
            triggerModal(ArchivingModalsEnum.UNARCHIVE_ERROR);
          } else if (result.limitCreationError) {
            triggerModal(ArchivingModalsEnum.CONFIRM_UNARCHIVE);
            showLimitQuotaModal(result.limitCreationError.errors[0].quota);
          }
        })
        .finally(() => {
          triggerLoading();
        });
    }
  };

  const fetchArchivedFields = useCallback(
    (force: boolean = false) => {
      const [, farmId] = getOrgAndFarmId();

      if (farmId && (currentFarmId !== farmId || force)) {
        setCurrentFarmId(farmId);
        setLoading(true);
        setLoadingRegionNames(true);

        const getFieldsPromise = service
          .getArchivedFields(farmId)
          .then((fields) => {
            setFields(fields);
            return fields;
          })
          .finally(() => {
            setLoading(false);
          });

        // Wait fields to be loaded before set regionNames
        return Promise.all([getFieldsPromise, service.getPropertyRegionNames(farmId)])
          .then(([fields, regionNames]) => {
            const fieldsWithNames = fields.map((field) =>
              getFieldWithParentName(field, regionNames)
            );
            setFields(fieldsWithNames);
            return fieldsWithNames;
          })
          .finally(() => setLoadingRegionNames(false));
      } else {
        return Promise.resolve([]);
      }
    },
    [currentFarmId]
  );

  const value = useMemo(
    () =>
      ({
        fields,
        loading,
        loadingRegionNames,
        openConfirmArchive: (selectedFields) => {
          triggerModal(ArchivingModalsEnum.CONFIRM_ARCHIVE);
          setState((state) => ({
            ...state,
            loading: false,
            selectedFields
          }));
        },
        openConfirmUnarchive: (field) => {
          triggerModal(ArchivingModalsEnum.CONFIRM_UNARCHIVE);
          setState((state) => ({
            ...state,
            loading: false,
            selectedFields: [field]
          }));
        },
        fetchArchivedFields
      }) as ArchivingContextValue,
    [fields, loading, loadingRegionNames, fetchArchivedFields]
  );

  return (
    <ArchivingContext.Provider value={value}>
      <ArchivingInternalProvider
        value={{
          ...state,
          triggerModal,
          goToSeasons,
          archiveFields,
          unarchiveField,
          resetState
        }}
      >
        <ConfirmArchive />
        <ArchiveError />
        <ForceArchive />
        <ConfirmUnarchive />
        <UnarchiveError />
      </ArchivingInternalProvider>
      {props.children}
    </ArchivingContext.Provider>
  );
}
