import { CloseOutlined } from '@ant-design/icons';
import { Button, DatePicker, Drawer, Form, Select } from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import downloadFields from 'services/downloadFields';
import IconLeft from '../../public/icon-left.svg';
import { getFieldsByPropertyId } from '../../redux/actions/fields';
import { AppConstants } from '../../utils/app.constants';
import { fieldsToAreaArray, translationWrapper } from '../../utils/commonMethods';
import { showNotification } from '../common/showNotification';
import { StyledContainer } from './associate-crops/associateCrop.styles';
import './associate-crops/associateCrops.less';
import { useGlobalAnalytics } from 'utils/analytics/contextual-tracking.hooks';

const DOWNLOAD_ERROR_MESSAGE = 'common.error.download.content';
const DOWNLOAD_FAILED = 'common.error.download.title';

const showFailureNotification = ({
  t,
  type = 'failed',
  title = DOWNLOAD_FAILED,
  message = DOWNLOAD_ERROR_MESSAGE
}) => {
  showNotification(type, t(title), t(message));
};

const ShapeFileFormatsDD = () => {
  const { t } = translationWrapper(useTranslation(), false);
  const { Option } = Select;
  const shapeFormats = [
    { id: 'geoJson', name: 'Geojson' },
    { id: 'kml', name: 'KML' },
    { id: 'shape', name: 'Shapefile' }
  ];

  return (
    <Form.Item
      name="format"
      label={<span className="label">{t('global.downalod_fields.select_format_label')}</span>}
      rules={[
        { required: true, message: t('global.downalod_fields.empty_error.select_format_label') }
      ]}
      className="form-container flex-col"
      data-testid="field-version-shape-format-drop-down"
    >
      <Select
        popupClassName="select"
        placeholder={t('common.placeholder.select')}
        size="large"
        style={{ height: '40px' }}
        className="input"
        showSearch={true}
      >
        {shapeFormats.map((format) => (
          <Option key={format.id} value={format.id}>
            {format.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const FieldVersionDate = (props) => {
  const { t } = translationWrapper(useTranslation(), false);
  return (
    <Form.Item
      className="input form-container flex-col  "
      name="date"
      rules={[{ required: true, message: t('global.download_fields.empty_error.select_date') }]}
      label={<span className="label">{t('common.placeholder.select_date')}</span>}
      data-testid="field-version-date-selection"
    >
      <DatePicker
        className="date-picker"
        allowClear={false}
        placeholder={t('common.placeholder.select')}
        format={props.dateFormat}
      />
    </Form.Item>
  );
};

export const DownloadShapeDrawer = (props) => {
  const [shouldSaveDisable, setShouldSaveDisabled] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const { t } = translationWrapper(useTranslation(), false);
  const [form] = Form.useForm();
  const params = useParams();

  const locationSearch = new URLSearchParams(window.location.search);
  const propId = locationSearch.get('property_id');

  const { farmId } = params;
  const { showDownloadShapeDrawer, setShowDownloadShapeDrawer, setSelectedFieldIds } = props;
  const { totalAreaSelected, property } = props;
  const track = useGlobalAnalytics();

  const checkAllFields = () => {
    if (form.getFieldValue('date') && form.getFieldValue('format')) {
      setShouldSaveDisabled(false);
    } else {
      setShouldSaveDisabled(true);
    }
  };

  useEffect(() => {
    if (!showDownloadShapeDrawer) {
      form.resetFields();
      setShouldSaveDisabled(true);
    }
  }, [showDownloadShapeDrawer, form]);

  const showPartiallyDownloadError = (downloadResponse, filteredFields, fieldsWihGeometry) => {
    if (downloadResponse instanceof Error) {
      showFailureNotification({ t: t, title: DOWNLOAD_FAILED });
    } else {
      if (filteredFields.length < totalAreaSelected.length) {
        showFailureNotification({
          t: t,
          title: 'common.error.download.partially_successful',
          message: 'common.error.download.some_fields_not_downloaded'
        });
      } else if (fieldsWihGeometry.length < totalAreaSelected.length) {
        showFailureNotification({
          t: t,
          title: 'common.error.download.partially_successful',
          message: 'common.error.download.no_geometry_on_date'
        });
      }
    }
  };

  const onSaveClick = async () => {
    const date = form.getFieldValue('date');
    const format = form.getFieldValue('format');
    setIsDownloading(true);
    try {
      const fieldsDetails = await getFieldsByPropertyId(
        farmId || propId,
        moment(date).format(AppConstants.DATE_FORMATS.YYYY_DASH_MM_DASH_DD),
        true
      );

      if (fieldsDetails.data.error) {
        showFailureNotification({ t });
        setIsDownloading(false);
        setShowDownloadShapeDrawer(false);
      } else {
        const filteredFields = get(fieldsDetails, 'data.content', []).filter(
          (field) =>
            totalAreaSelected.find((selectedField) => selectedField.id === field.id) !== undefined
        );

        const fieldsWihGeometry = filteredFields.filter(
          (area) => area.geometry && area.geometry !== null
        );

        if (isEmpty(filteredFields)) {
          showFailureNotification({
            t: t,
            message: 'common.error.download.please_review_start_and_end_date'
          });
        } else if (fieldsWihGeometry.length === 0 || isEmpty(fieldsWihGeometry)) {
          showFailureNotification({
            t: t,
            message: 'common.error.download.no_geometry_on_date'
          });
        } else {
          const labels = {
            download_failed_title: t(DOWNLOAD_FAILED),
            download_failed_message: DOWNLOAD_ERROR_MESSAGE,
            ok: t('common.button.ok'),
            no_geometry_found: t('common.error.no_geometry_found'),
            no_field_selected: t('common.text.no_field_selected')
          };
          const downloadResponse = downloadFields(filteredFields, property, labels, format);
          track('Fields - Downloaded field', {
            areaInHectars: fieldsToAreaArray(selectedFields)
          });
          showPartiallyDownloadError(downloadResponse, filteredFields, fieldsWihGeometry);
        }
        setShowDownloadShapeDrawer(false);
        setIsDownloading(false);
        setSelectedFieldIds([]);
      }
    } catch (error) {
      setIsDownloading(false);
      setShowDownloadShapeDrawer(false);
      showFailureNotification({ t: t, title: DOWNLOAD_FAILED });
      setSelectedFieldIds([]);
    }
  };

  return (
    <Drawer
      className="drawer"
      title={t('global.download_fields.title')}
      placement="right"
      open={showDownloadShapeDrawer}
      maskClosable={false}
      width={400}
      closeIcon={<CloseOutlined onClick={() => setShowDownloadShapeDrawer(false)} />}
      forceRender
    >
      <div className="selected-area-container" data-testid="download-shape-selected-area">
        <img src={IconLeft} alt="Icon Left" />
        <div className="selected-area">{`${props.totalAreaSelected.length} ${t(
          'common.text.field_selected'
        )}`}</div>
      </div>
      <StyledContainer
        data-testid="download-drawer-form-container"
        className="page-container flex-col field-download-drawer-container"
        style={{ height: `calc(100% - 48px)` }}
      >
        <Form
          onFieldsChange={checkAllFields}
          form={form}
          layout={'vertical'}
          requiredMark={false}
          className="form-container flex-col flex-grow-1 m-0"
        >
          <div className="flex-grow-1">
            <FieldVersionDate dateFormat={props.dateFormat} />
            <ShapeFileFormatsDD />
          </div>
          <div className="flex-row flex-end">
            <Button
              type="secondary"
              size="large"
              className="no-bg-button"
              style={{ marginRight: '8px' }}
              data-testid="close-download-shape-drawer"
              onClick={() => {
                setShowDownloadShapeDrawer(false);
                setSelectedFieldIds([]);
              }}
            >
              {t('common.button.cancel')}
            </Button>
            <Button
              onClick={() => {
                onSaveClick();
              }}
              data-testid="download-shapes-btn"
              type="primary"
              htmlType="submit"
              size="large"
              loading={isDownloading}
              disabled={shouldSaveDisable}
            >
              {t('common.button.download')}
            </Button>
          </div>
        </Form>
      </StyledContainer>
    </Drawer>
  );
};
