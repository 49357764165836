import { StyledModal } from 'app/common';
import {
  ArchivingModalsEnum,
  useArchivingInternal
} from 'app/fields/archiving/archiving-internal.provider';
import { Trans, useTranslation } from 'react-i18next';
import { isFieldCreatedOrEditedToday } from '../../../fields.util';
import { useMemo } from 'react';

export function ForceArchive() {
  const { t } = useTranslation();

  const {
    loading,
    modals: { [ArchivingModalsEnum.FORCE_ARCHIVE]: open },
    selectedFields,
    triggerModal,
    archiveFields
  } = useArchivingInternal();

  const onClose = () => {
    triggerModal(ArchivingModalsEnum.CONFIRM_ARCHIVE);
    triggerModal(ArchivingModalsEnum.FORCE_ARCHIVE);
  };

  const nrOfFields = selectedFields.length;
  const invalidFields = useMemo(
    () => selectedFields.filter((field) => isFieldCreatedOrEditedToday(field.valid_since)),
    [selectedFields]
  );
  const nrOfInvalidFields = invalidFields.length;

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      title={t('archiving.notifications.force_archive.title')}
      cancel={{
        onClick: onClose,
        text: t('common.button.cancel')
      }}
      ok={{
        onClick: () => archiveFields(true),
        text: t('archiving.notifications.force_archive.archive_button')
      }}
      loading={loading}
    >
      <div style={{ paddingLeft: 40 }}>
        {nrOfFields > 1 ? (
          <Trans
            key="archiving.notifications.force_archive.description"
            count={nrOfFields}
            values={{
              nrOfInvalidFields,
              nrOfFields
            }}
          >
            You edited {{ nrOfInvalidFields }} of the {{ nrOfFields }} selected fields today. If you
            archive these fields now, you will lose the edits made today in:
            <ul style={{ margin: '1em 0' }}>
              {invalidFields.map((field) => (
                <li key={field.id}>{field.name}</li>
              ))}
            </ul>
            To keep today’s edits in the history of the field, archive it from tomorrow onwards.
          </Trans>
        ) : (
          t('archiving.notifications.force_archive.description', { count: 1 })
        )}
        <br />
        <br />
        <b>{t('archiving.notifications.force_archive.disclaimer', { count: nrOfFields })}</b>
      </div>
    </StyledModal>
  );
}
