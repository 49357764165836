export const isLocal = process.env.REACT_APP_envName === 'local';

export const i18nToMoment = {
  'es-419': 'es',
  'pt-BR': 'pt-br',
  'es-AR': 'es'
};

export const defaultLanguage = 'en';

export const topBarHeight = 54;
