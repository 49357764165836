import { Tooltip } from 'antd';
import { useRef, useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useArchiving } from '../../archiving.provider';
import { UnarchiveIcon } from '../../unarchive.icon';
import * as Styled from '../archived-list.style';
import * as CommonStyled from '../../archiving.style';

interface ArchivedFieldProps {
  id: string;
  name: string;
  area: number;
  checked: boolean;
  onSelect: () => void;
}
export function ArchivedField({ id, checked, name, area, onSelect }: ArchivedFieldProps) {
  const { openConfirmUnarchive } = useArchiving();
  const ref = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (ref.current && ref.current.scrollWidth > ref.current.clientWidth) setShowTooltip(true);
  }, []);

  return (
    <Tooltip title={showTooltip ? name : null}>
      <Styled.FieldContainer>
        <Styled.StyledCheckbox type="checkbox" checked={checked} onChange={onSelect} />
        <CommonStyled.FieldInfo ref={ref}>
          <span>{name}</span>
          <CommonStyled.FieldArea>
            <span>{area.toFixed(2)} ha</span>
            <span className="archived-tag">{t('archiving.archived_list.field_tag')}</span>
          </CommonStyled.FieldArea>
        </CommonStyled.FieldInfo>
        <Tooltip
          overlayClassName="white-bg-tooltip"
          title={t('archiving.archived_list.unarchive_tooltip')}
        >
          <UnarchiveIcon
            onClick={() => openConfirmUnarchive({ id, name, valid_since: null })}
            style={{ cursor: 'pointer' }}
            role="button"
            aria-label={t('archiving.archived_list.unarchive_label', { name })!}
          />
        </Tooltip>
      </Styled.FieldContainer>
    </Tooltip>
  );
}
