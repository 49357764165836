import { captureMessage } from '@sentry/react';
import area from '@turf/area';
import bbox from '@turf/bbox';
import { notification } from 'antd';
import i18n from '../../i18n';

export const REGION_SIZE_REGEX = /^(\s*|\d+([.,]\d{1,2})?)$/;
export const FIELD_SIZE_REGEX = /^\d+(\.\d{1,2})?$/;

export const FIELD_SIZE_LIMIT_IN_HA = 5000;

const SQUARE_METER_TO_HA_CONVERSION_FACTOR = 10000;
/**
 * Function to check if the provided geometry exceeds the size limit in hectares
 *
 * @param geometry GeoJSON feature (object with type and coordinates)
 * @returns boolean, true if geometry exceeds the size limit
 */
export const isGeometryExceedingSizeLimit = (geometry: GeoJSON.Feature) => {
  try {
    return area(geometry) / SQUARE_METER_TO_HA_CONVERSION_FACTOR > FIELD_SIZE_LIMIT_IN_HA;
  } catch {
    return false;
  }
};

export const isFeaturesBoundingBoxValid = (features: GeoJSON.Feature[]) => {
  try {
    const [minLng, minLat, maxLng, maxLat] = bbox({
      type: 'FeatureCollection',
      features
    });
    if (
      [minLat, maxLat].every((lat) => isFinite(lat) && Math.abs(lat) <= 90) &&
      [minLng, maxLng].every((lng) => isFinite(lng) && Math.abs(lng) <= 180)
    ) {
      return true;
    }
  } catch {
    captureMessage(
      'Problem when validating features bounding box, invalid geometry or projection provided',
      'info'
    );
  }
  notification.error({
    message: i18n.t('upload_field.geojson_input_labels.could_not_process_fields')
  });
  return false;
};

/**
 * Function to check if the provided field is archived. Archived fields have the `valid_until` property set to a date in the past.
 *
 * @param valid_until string or null
 * @returns boolean, true if valid_until is in the past
 */
export const isFieldArchived = (valid_until?: string | null) => {
  if (!valid_until) return false;

  const validUntilDate = new Date(valid_until);
  const todayDate = new Date(new Date().setHours(0, 0, 0));

  const utcUntilDate = new Date(
    validUntilDate.getUTCFullYear(),
    validUntilDate.getUTCMonth(),
    validUntilDate.getUTCDate()
  );
  const utcTodayDate = new Date(
    todayDate.getUTCFullYear(),
    todayDate.getUTCMonth(),
    todayDate.getUTCDate()
  );

  return utcTodayDate > utcUntilDate;
};

/**
 * Function to check if the provided field is created or edited today. These fields have the `valid_since` property set to a date equal to today.
 *
 * @param valid_since string or null
 * @returns boolean, true if valid_since is today
 */
export const isFieldCreatedOrEditedToday = (valid_since?: string | null) => {
  if (!valid_since) return false;

  const valiSinceDate = new Date(valid_since);
  const todayDate = new Date(new Date().setHours(0, 0, 0));

  const utcSinceDate = new Date(
    valiSinceDate.getUTCFullYear(),
    valiSinceDate.getUTCMonth(),
    valiSinceDate.getUTCDate()
  );
  const utcTodayDate = new Date(
    todayDate.getUTCFullYear(),
    todayDate.getUTCMonth(),
    todayDate.getUTCDate()
  );

  return utcTodayDate.getTime() === utcSinceDate.getTime();
};
