import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { request } from '../../utils/axios';
import { getHeaders } from '../../utils/commonMethods';
import { manageRedirection } from './../../pages/multi-region/commonFunctions';
import {
  FETCH_REGIONS_BY_PROPERTY_ID_ERROR,
  FETCH_REGIONS_BY_PROPERTY_ID_LOADING,
  FETCH_REGIONS_BY_PROPERTY_ID_SUCCESS,
  GET_MULTIPLE_PROPS_REGIONS_ERROR,
  GET_MULTIPLE_PROPS_REGIONS_LOADING,
  GET_MULTIPLE_PROPS_REGIONS_SUCCESS,
  GET_REGION_TREE_BY_REGION_ID_LOADING,
  GET_REGION_TREE_BY_REGION_ID_SUCCESS,
  GET_REGION_TREE_BY_REGION_ID__ERROR,
  RESET_REGION_LIST_DATA
} from './types';

export function resetRegionListData() {
  return {
    type: RESET_REGION_LIST_DATA
  };
}

export const getRegionsByPropertyId = async (propId) => {
  try {
    return await request.get(`/v2/properties/${propId}/region-tree`, {
      headers: getHeaders()
    });
  } catch (e) {
    return {
      data: { content: [] },
      error: e
    };
  }
};

export const createRegionByPropId = async ({
  property,
  name,
  geometry,
  declared_area,
  orgid,
  shouldRedirect = true,
  parent_id = null,
  eventDate,
  navigate
}) => {
  const data = {
    name,
    property_id: property.id,
    parent_id: parent_id || property.root_region_id,
    geometry,
    declared_area,
    event_date: eventDate
  };

  const regionsRes = await request.post(`/v2/regions`, data, {
    headers: getHeaders()
  });

  if (!isEmpty(regionsRes) && !isEmpty(regionsRes.data.errors)) {
    throw regionsRes.data.errors;
  }

  if (!isEmpty(regionsRes) && regionsRes.status === 201 && shouldRedirect) {
    manageRedirection({ orgId: orgid, farmId: property.id, navigate: navigate });
  }
  return regionsRes;
};

function getRegionTreeByRegionIdLoading() {
  return {
    type: GET_REGION_TREE_BY_REGION_ID_LOADING
  };
}

const getRegionTreeByRegionIdSuccess = (region) => {
  return (dispatch) => {
    dispatch({
      type: GET_REGION_TREE_BY_REGION_ID_SUCCESS,
      payload: region
    });
  };
};

const getRegionTreeByRegionIdError = (error) => {
  return {
    type: GET_REGION_TREE_BY_REGION_ID__ERROR,
    error
  };
};

export const getRegionTreeByRegionId = (regionId) => {
  return (dispatch) => {
    dispatch(getRegionTreeByRegionIdLoading());
    request
      .get(`/v2/regions/${regionId}/region-tree`, {
        headers: getHeaders()
      })
      .then((result) => {
        if (result.error) {
          throw result.error;
        }
        dispatch(getRegionTreeByRegionIdSuccess(result.data));
      })
      .catch((error) => {
        dispatch(getRegionTreeByRegionIdError(error));
      });
  };
};

const getQueryString = (referenceDate, includedArchived) => {
  let queryString = `?include_archived=${includedArchived}`;

  if (referenceDate && referenceDate !== '') {
    queryString += `&reference_date=${referenceDate}`;
  }

  return queryString;
};

export const getRegionsByPropId = (propertyId, eventDate = '', includeArchived = false) => {
  return (dispatch) => {
    dispatch({ type: FETCH_REGIONS_BY_PROPERTY_ID_LOADING });

    request
      .get(
        `/v2/properties/${propertyId}/region-tree${getQueryString(eventDate, includeArchived)}`,
        {
          headers: getHeaders()
        }
      )
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch({ type: FETCH_REGIONS_BY_PROPERTY_ID_SUCCESS, payload: res.data });
      })
      .catch((error) => {
        dispatch({ type: FETCH_REGIONS_BY_PROPERTY_ID_ERROR, payload: error });
      });
  };
};

export const regionBulkOperationsByParentId = async ({
  parentId,
  regionsObj,
  orgId,
  farmId,
  operation = 'create',
  shouldRedirect = true,
  eventDate,
  navigate
}) => {
  try {
    const data = {
      strict: true,
      region_set: {
        type: 'FeatureCollection',
        features: regionsObj.map(({ id, name, geometry, adjustedSize }) => {
          return {
            type: 'Feature',
            id,
            geometry,
            properties: {
              name,
              parent_id: parentId,
              operation: operation,
              declared_area: adjustedSize,
              ...((operation !== 'delete' && !isEmpty(eventDate) && { event_date: eventDate }) ||
                {})
            }
          };
        })
      }
    };

    const propertyRes = await request.post(`/v2/properties/${farmId}/regions/updates`, data, {
      headers: getHeaders()
    });
    if (!isEmpty(propertyRes) && propertyRes.status === 201) {
      if (propertyRes.data.status === 'REJECTED') {
        throw propertyRes.data.errors;
      }
      if (shouldRedirect) {
        manageRedirection({
          orgId,
          farmId,
          parentId,
          newFields: regionsObj,
          isFildsAdded: false,
          navigate: navigate
        });
      }
    }
    return propertyRes;
  } catch (ex) {
    const response = ex.response;
    if (isEmpty(get(response, 'data.errors'))) {
      throw ex;
    }
    throw get(response, 'data.errors');
  }
};

export const deleteRegionByRegionId = (regionId) => {
  return request.delete(`/v2/regions/${regionId}`, {
    headers: getHeaders()
  });
};

export const editRegionById = async ({
  property,
  name,
  geometry,
  declared_area,
  regionId,
  parent_id,
  event_date
}) => {
  let regionEventDate;
  await request
    .get(`/v2/regions/${regionId}`, { headers: getHeaders() })
    .then((response) => {
      regionEventDate = response.data.event_date;
    })
    .catch(() => {
      regionEventDate = event_date;
    });
  const data = {
    name,
    property_id: property.id,
    parent_id: parent_id || property.root_region_id,
    geometry,
    declared_area,
    id: regionId,
    event_date: regionEventDate
  };
  return request.put(`/v2/regions/${regionId}`, data, {
    headers: getHeaders()
  });
};

/**
 *
 * @param {*} param0
 * @returns
 */
export const getRegionsByIds = (ids) => {
  return request
    .post(
      `/v2/regions/ids`,
      { ids },
      {
        headers: getHeaders()
      }
    )
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      return res.data.content;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * to fetch regions of multiple properties (sample page: Org Seasons)
 * @param {*} propIds
 * @returns
 */
export const fetchRegionsOfMultipleProperties = (
  propIds,
  eventDate = '',
  includeArchived = false
) => {
  return async (dispatch) => {
    dispatch({
      type: GET_MULTIPLE_PROPS_REGIONS_LOADING
    });
    const regionsOfAllProps = propIds.map((propId) => {
      return request.get(
        `/v2/properties/${propId}/region-tree${getQueryString(eventDate, includeArchived)}`,
        {
          headers: getHeaders()
        }
      );
    });
    try {
      const allResponses = await Promise.all(regionsOfAllProps);
      const regions = {};
      allResponses.forEach((item) => {
        const propId = get(item, 'data.property_id', '');
        regions[propId] = get(item, 'data', {});
      });
      dispatch({
        type: GET_MULTIPLE_PROPS_REGIONS_SUCCESS,
        payload: regions
      });
    } catch (error) {
      dispatch({
        type: GET_MULTIPLE_PROPS_REGIONS_ERROR,
        payload: error
      });
    }
  };
};
