import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ArchiveIcon } from '../archive.icon';
import { useArchiving } from '../archiving.provider';
import * as Styled from './archive-button.style';

interface ArchiveBtnProps {
  selectedFields: Array<{ id: string; name: string; valid_since: string | null }>;
  canArchive: boolean;
  dark?: boolean;
}

export function ArchiveButtonContainer({
  canArchive,
  selectedFields,
  dark = false
}: ArchiveBtnProps) {
  const { farmSettingsArchivingCws10301EnableArchive } = useFlags();
  const { t } = useTranslation();
  const { openConfirmArchive } = useArchiving();

  if (!farmSettingsArchivingCws10301EnableArchive) return null;

  return (
    <Tooltip
      overlayClassName="white-bg-tooltip"
      title={!canArchive && t('archiving.tooltips.permission', { count: selectedFields.length })}
    >
      <Styled.Button
        $dark={dark}
        $disabled={!canArchive}
        onClick={() => {
          if (canArchive) {
            openConfirmArchive(selectedFields);
          }
        }}
      >
        <ArchiveIcon />
        {t('archiving.archive_button')}
      </Styled.Button>
    </Tooltip>
  );
}
