import { StyledModal } from 'app/common';
import {
  ArchivingModalsEnum,
  useArchivingInternal
} from 'app/fields/archiving/archiving-internal.provider';
import { useTranslation } from 'react-i18next';

export function ConfirmArchive() {
  const { t } = useTranslation();

  const {
    loading,
    modals: { [ArchivingModalsEnum.CONFIRM_ARCHIVE]: open },
    selectedFields,
    triggerModal,
    archiveFields
  } = useArchivingInternal();

  const nrOfFields = selectedFields.length;

  const onClose = () => {
    triggerModal(ArchivingModalsEnum.CONFIRM_ARCHIVE);
  };

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      title={t('archiving.notifications.confirm_archive.title', { count: nrOfFields })}
      cancel={{
        onClick: onClose,
        text: t('common.button.cancel')
      }}
      ok={{
        onClick: () => archiveFields(false),
        text: t('archiving.notifications.confirm_archive.archive_button', { count: nrOfFields })
      }}
      loading={loading}
    >
      <div style={{ paddingLeft: 40 }}>
        <span>
          {t('archiving.notifications.confirm_archive.description', { count: nrOfFields })}
        </span>
        <br />
        <br />
        <span>{t('archiving.notifications.confirm_archive.disclaimer')}</span>
      </div>
    </StyledModal>
  );
}
