/* eslint-disable */
import { captureException, setUser } from '@sentry/react';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { RbacAccessHoC } from 'rbac/rbacAccessHoC';
import { lazy, Suspense, useEffect, useState } from 'react';
import { isChrome, isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { setLaunchDarklyFlags } from 'redux/actions/launchDarkly';
import { getRolloutLoading } from 'redux/selectors/rolloutSelector';
import styled from 'styled-components';
import { useGlobalAnalytics } from 'utils/analytics/contextual-tracking.hooks';
import { defaultLanguage, i18nToMoment } from './app.constant';
import { FooterNotification } from './components/common/footerNotification';
import { RetroLocationModal } from './components/common/modal/retroLocationModal';
import { ZendeskCustomBtn } from './components/common/zendeskWidget';
import { Loader } from './components/loader/loader';
import { RedirectionLoader } from './components/loader/redirectionLoader';
import { toThemeHoc } from './components/styles/theme';
import i18n from './i18n';
import { fetchOrgsList } from './redux/actions/orgsList';
import { initRollout } from './redux/actions/rollout';
import { fetchTermsAndConditions } from './redux/actions/termsAndConditions';
import { fetchUserProfile, fetchUserWorkspace, openLocationModal } from './redux/actions/user';
import { CustomRouter } from './routes/customRouter';
import {
  getAccessToken,
  getAccountsOAuthUrl,
  getAppHostsInfo,
  getLanguageCode,
  getLocaleTempUsingCookies,
  getLocaleUsingCookies,
  getWorkspaceAndOrgsSequentially,
  isAccessTokenAvailable,
  translationWrapper
} from './utils/commonMethods';
import { history, publicItems, URL_CONSTANTS } from './utils/history';
import { iff } from './utils/iff';
import { getUserLocationDetails } from './utils/ipApi';
import { getLocationFromBrowser } from './utils/location';
import { setUserId, setUserProperties } from '@cw-elements/analytics-provider';

const Private = lazy(() => import('./routes/private'));
const Public = lazy(() => import('./routes/public'));

/**
 * styled root component
 */
const StyledRoot = styled.div`
  font-family: ${(props) => get(props, 'theme.font.family')};
  height: 100%;
  min-height: 100%;
  * {
    box-sizing: border-box;
  }
`;

const App = (props) => {
  const track = useGlobalAnalytics();
  const flags = useFlags();
  const ldClient = useLDClient();
  const { terms, termsSuccess, editUserDetailsSuccess } = props;
  const { t } = translationWrapper(useTranslation(), false);
  const [isLoading, setIsLoading] = useState(
    isAccessTokenAvailable() && !publicItems.includes(location.pathname)
  );
  const userId = get(props.user, 'id', undefined);
  const jobTitle = get(props.user, 'job_title', undefined);
  const isRolloutLoading = useSelector(getRolloutLoading);
  /**
   * if has any unsigned document for his current location
   * redirect them to sign legal documents page
   */
  useEffect(() => {
    if (termsSuccess && terms.length > 0 && props.isSignLegelDocumentsEnabled) {
      const { account } = getAppHostsInfo();
      window.location.href = `https://${account}/sign-legal-documents`;
    }
  }, [terms, termsSuccess, props.isSignLegelDocumentsEnabled]);

  /**
   *
   */
  useEffect(() => {
    if (!isRolloutLoading && editUserDetailsSuccess) {
      props.fetchTermsAndConditions();
    }
  }, [editUserDetailsSuccess, isRolloutLoading]);

  useEffect(() => {
    if (userId && jobTitle) {
      setUserId(userId);
      setUserProperties({ jobTitle });
    }
  }, [userId, jobTitle]);

  const fetchUserData = async () => {
    if (isAccessTokenAvailable()) {
      await props.fetchUserProfile().then(async (res) => {
        if (res) {
          const id = res.id ?? res?.data?.id;
          const email = res.email ?? res?.data?.email;
          const name = res.name ?? res?.data?.name;
          const locale = res?.locale ?? res?.data?.locale;
          const country = res?.country_code ?? res?.data?.country_code;

          const userInterfaceLanguage = (
            localStorage.getItem('currentLanguage') ?? localStorage.getItem('i18nextLng')
          )?.toLowerCase();

          const userLocation = getLocationFromBrowser();
          props.initRollout({ userContext: { id, locale, userLocation } });

          window.pendo.initialize({
            visitor: {
              id,
              interfaceLanguage: userInterfaceLanguage ?? null,
              language: navigator.language || navigator.userLanguage,
              userLanguage: locale ?? userInterfaceLanguage
            },
            account: {
              id
            }
          });
          setUser({
            id,
            email,
            name,
            language: i18n.language,
            country
          });
          try {
            if (id && email && country) {
              ldClient?.identify({ kind: 'user', key: id, email, country });
            }
          } catch (error) {
            captureException(error);
          }
        }
      });
      getWorkspaceAndOrgsSequentially({
        fetchUserWorkspace: props.fetchUserWorkspace,
        fetchOrgsList: props.fetchOrgsList
      });
    }
  };

  useEffect(() => {
    if (isAccessTokenAvailable() && !isRolloutLoading) {
      props.fetchTermsAndConditions();
    }
  }, [isRolloutLoading, isAccessTokenAvailable()]);

  useEffect(() => {
    getUserLocationDetails();
    fetchUserData();
  }, [isAccessTokenAvailable()]);

  useEffect(() => {
    track('Loaded Farm Settings', { path: window.location.pathname });

    const lngByCookie =
      getLanguageCode(getLocaleTempUsingCookies()) || getLocaleUsingCookies() || defaultLanguage;
    const lng = i18nToMoment[lngByCookie] ?? lngByCookie;

    if (process.env.NODE_ENV !== 'test' && lng !== defaultLanguage) {
      import(`moment/locale/${lng}`).then().catch();
    }

    moment.locale(lng);
  }, []);

  useEffect(() => {
    if (props.userSuccess && !props.user.country_code && !props.updatedUser.country_code) {
      props.openLocationModal();
    }
  }, [props.termsSuccess, props.userSuccess]);

  useEffect(() => {
    const { location } = history;
    const query = Object.fromEntries(new URLSearchParams(location.search));
    const workspaceFilter = query.workspace ? '?workspace=' + query.workspace : '';

    if (props.orgsSuccess && props.termsSuccess) {
      if (!location.pathname.includes('/app') && !publicItems.includes(location.pathname)) {
        if (!isEmpty(props.orgs) && props.orgs.length === 1) {
          history.push(URL_CONSTANTS.LANDING_ORGANIZATION({ orgId: props.orgs[0] }));
        } else {
          const legacyHallOfOrgsUrl = `${URL_CONSTANTS.HALL_OF_ORG()}${workspaceFilter}`;
          const urlToNavigate = flags.farmSettingsEntitiesHallCws11468EnableMfe
            ? URL_CONSTANTS.HALL_OF_ENTITIES()
            : legacyHallOfOrgsUrl;
          history.push(urlToNavigate);
        }
      }
      setIsLoading(false);
    } else if (!isEmpty(props.orgsError) || !isEmpty(props.termsError)) {
      setIsLoading(false);
      const legacyHallOfOrgsUrl = `${URL_CONSTANTS.HALL_OF_ORG()}${workspaceFilter}`;
      const urlToNavigate = flags.farmSettingsEntitiesHallCws11468EnableMfe
        ? URL_CONSTANTS.HALL_OF_ENTITIES()
        : legacyHallOfOrgsUrl;
      history.push(urlToNavigate);
    }
  }, [props.orgsSuccess, props.orgsError, props.termsSuccess, props.termsError]);

  useEffect(() => {
    if (!isEmpty(props.termsError)) {
      if (props.termsError.toString().includes('Request failed with status code 401')) {
        window.location.href = getAccountsOAuthUrl();
      } else {
        setIsLoading(false);
      }
    }
  }, [props.termsError]);

  useEffect(() => {
    props.setLaunchDarklyFlags(flags);
  }, [flags, props.setLaunchDarklyFlags]);

  if (props?.orgsError?.toString().includes('failed with status code 401')) {
    return <Loader />;
  } else {
    return (
      <StyledRoot>
        <Helmet>
          <title>{t('common.text.farm_settings')}</title>
        </Helmet>
        <CustomRouter history={history}>
          <Suspense
            fallback={iff(
              Object.keys(getAccessToken()).length === 0,
              <RedirectionLoader />,
              <Loader showMessage={false} />
            )}
          >
            <Routes>
              <Route
                exact
                path="/app/*"
                element={
                  <RbacAccessHoC>
                    <Private
                      history={history}
                      location={location}
                      // match={match}
                      loading={props.termsLoading || isLoading}
                      orgsError={props.orgsError}
                      termsError={props.termsError}
                      orgs={props.orgs}
                    />
                  </RbacAccessHoC>
                }
              />
              <Route path="/*" element={<Public />} />
            </Routes>
          </Suspense>
        </CustomRouter>
        <RetroLocationModal />
        <FooterNotification isChrome={isChrome} isMobile={isMobile} />
        <ZendeskCustomBtn />
      </StyledRoot>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userProfile,
    userSuccess: get(state, 'user.success', {}),
    orgs: get(state, 'orgsList.allOrgs.result', []),
    updatedUser: get(state, 'userAccessManagement.updatedUserDetails', {}),
    terms: get(state, 'termsAndConditions.unsignedTermsIdList', []),
    termsSuccess: get(state, 'termsAndConditions.success', false),
    termsLoading: get(state, 'termsAndConditions.loading', false),
    termsError: get(state, 'termsAndConditions.error', null),
    orgsLoading: get(state, 'orgsList.loading', []),
    orgsError: get(state, 'orgsList.error', null),
    orgsSuccess: get(state, 'orgsList.isFetchOrgListSuccess', false),
    isLocationModalOpen: get(state, 'isLocationModalOpen', false),
    editUserDetailsSuccess: get(state, 'userAccessManagement.editUserDetailsSuccess', false),
    isSignLegelDocumentsEnabled: state.rollout.flags.isSignLegelDocumentsEnabled
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchOrgsList: (queryParams) => dispatch(fetchOrgsList(queryParams)),
  fetchUserProfile: () => dispatch(fetchUserProfile()),
  fetchTermsAndConditions: () => dispatch(fetchTermsAndConditions()),
  fetchUserWorkspace: () => dispatch(fetchUserWorkspace()),
  initRollout: (configs) => dispatch(initRollout(configs)),
  setLaunchDarklyFlags: (flags) => dispatch(setLaunchDarklyFlags(flags)),
  openLocationModal: () => dispatch(openLocationModal(true))
});

export const AppLazy = connect(
  mapStateToProps,
  mapDispatchToProps
)(toThemeHoc(App, 'defaultTheme'));
